import styled from '@emotion/styled';
import ChargeFlowImg from 'ui/images/charge_flow.png';
import ChargeFlowSpImg from 'ui/images/charge_flow_sp.png';
import TicketPriceImg from 'ui/images/ticket_price.png';
import TicketPriceSpImg from 'ui/images/ticket_price_sp.png';
import { RegistrationButton } from 'ui/components/elements/Button/RegistrationButton';
import { VIEWPORT_SIZES } from 'utils/responsive';
import { Only } from 'ui/components/elements/Only/Only';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-light-3);

  img {
    width: 1026px;
    margin: 0 auto;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    gap: 8.53vw;
    @supports (width: 100svw) {
      gap: 8.53svw;
    }
    img {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const Text = styled.div`
  font-size: var(--font-xx-large);
  font-weight: var(--font-extra-bold);
  line-height: 32px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 6.4vw;
    margin-bottom: 1.8vw;
    @supports (width: 100svw) {
      margin-bottom: 1.8svw;
      font-size: 4.27svw;
      line-height: 6.4svw;
    }
  }
`;

const Content = styled.div`
  width: 1026px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    @supports (width: 100svw) {
      width: 91.47svw;
    }
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    margin: 0 auto;
    @supports (width: 100svw) {
      width: 91.47svw;
    }
  }
`;

export const Charge: React.FC = () => {
  return (
    <Wrapper>
      <Title>ご利用の流れと料金</Title>
      <Content>
        <Text>
          募集開始は無料で何度でも。インタビューしたいユーザーと最初のメッセージを開始するタイミングでチケットの購入が必要です。
        </Text>
        <Only pc>
          <img src={ChargeFlowImg} alt="ご利用の流れと料金1" />
          <img src={TicketPriceImg} alt="ご利用の流れと料金2" />
        </Only>
        <Only sp>
          <img src={ChargeFlowSpImg} alt="ご利用の流れと料金1" />
          <img src={TicketPriceSpImg} alt="ご利用の流れと料金2" />
        </Only>
      </Content>
      <ButtonContainer>
        <RegistrationButton />
      </ButtonContainer>
    </Wrapper>
  );
};
