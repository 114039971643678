import { type IPublicProjectQuery } from 'application/querySchemas/IPublicProjectQuery';
import { type IPushedProjectsClient } from 'interfaceAdapter/gatewaySchemas/IPushedProjectsClient';
import { BuildProjectInput, type IProjectFactory } from 'domain/entities/factories/Project';
import { type IProject } from 'domain/entities/Project/Project';
import { inject, injectable } from 'tsyringe';

@injectable()
export class PublicProjectQuery implements IPublicProjectQuery {
  constructor(
    @inject('IPushedProjectsClient') public readonly pushedProjectsClient: IPushedProjectsClient,
    @inject('IProjectFactory') public readonly factory: IProjectFactory,
  ) {}

  public async fetchPushedProjects({
    offset,
    limit,
  }: {
    offset: number;
    limit?: number;
  }): Promise<{ projects: IProject[]; open_projects_count: number }> {
    const { data, message } = await this.pushedProjectsClient.post({
      offset,
      limit,
    });

    if (message) {
      throw new Error(message);
    }

    return {
      projects: data.public_projects.projects.map((project) => this.factory.buildProject(project as BuildProjectInput)),
      open_projects_count: data.open_projects_count,
    };
  }
}
