import styled from '@emotion/styled';
import UsersImg from 'ui/images/users.png';
import UsersSpImg from 'ui/images/users_sp.png';
import UserAttributesImg from 'ui/images/user_attributes.png';
import UserAttributesSpImg from 'ui/images/user_attributes_sp.png';
import { RegistrationButton } from 'ui/components/elements/Button/RegistrationButton';
import { VIEWPORT_SIZES } from 'utils/responsive';
import { Only } from 'ui/components/elements/Only/Only';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--primary);

  img {
    width: 1026px;
    margin: 0 auto;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    gap: 8.53vw;
    @supports (width: 100svw) {
      gap: 8.53svw;
    }
    img:first-of-type {
      width: 100%;
    }
    img:last-of-type {
      width: 91.47vw;
      margin-top: 8.53vw;

      @supports (width: 100svw) {
        width: 91.47svw;
        margin-top: 8.53svw;
      }
    }
  }
`;

const Text = styled.div`
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 42px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 5.87vw;
    line-height: 8.8vw;
    width: 91.47vw;
    margin: 0 auto;

    @supports (width: 100svw) {
      font-size: 5.87svw;
      line-height: 8.8svw;
      width: 91.47svw;
    }
  }
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    margin: 0 auto;
    @supports (width: 100svw) {
      width: 91.47svw;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Users: React.FC = () => {
  return (
    <Wrapper>
      <Title>
        Spreadyの登録ユーザーは
        <Only sp>
          <br />
        </Only>
        こんな方
      </Title>
      <Only pc>
        <ImageWrapper>
          <img src={UsersImg} alt="Spreadyの登録ユーザーはこんな方" />
          <img src={UserAttributesImg} alt="Spreadyの登録ユーザーはこんな方" />
        </ImageWrapper>
      </Only>
      <Only sp>
        <img src={UsersSpImg} alt="Spreadyの登録ユーザーはこんな方" />
        <img src={UserAttributesSpImg} alt="Spreadyの登録ユーザーはこんな方" />
      </Only>
      <Text>
        Spreadyなら、 <br />
        20-40代のビジネスパーソンの生の声を聞くことができます！
      </Text>
      <ButtonContainer>
        <RegistrationButton />
      </ButtonContainer>
    </Wrapper>
  );
};
