import styled from '@emotion/styled';
import { useSessions } from './useSessions';
import { SessionCards } from 'ui/components/patterns/SessionCards/SessionCards';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    gap: 8.53vw;
    @supports (width: 100svw) {
      gap: 8.53svw;
    }
  }
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const Content = styled.div`
  width: 880px;
  margin: 0 auto;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
  }
`;

export const Sessions: React.FC = () => {
  const { projectList } = useSessions();

  return (
    <Wrapper>
      <Title>募集案件例</Title>
      <Content>{projectList && <SessionCards projectList={projectList} />}</Content>
    </Wrapper>
  );
};
