import { useState } from 'react';
import styled from '@emotion/styled';
import { QaItem } from 'ui/components/features/QaItem/QaItem';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-light-3);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    padding: 10.67vw 2.67vw;
    gap: 8.53vw;
    @supports (width: 100svw) {
      padding: 10.67svw 2.67svw;
      gap: 8.53svw;
    }
  }
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const Container = styled.div`
  width: 1026px;
  margin: 0 auto;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
  }
`;

const QaList = [
  {
    question: <span>「Spready」の他社との違い・強みを教えてください</span>,
    answer: (
      <span>
        「Spready」について、選ばれる理由は以下の4点でございます。
        <br />
        <br />
        1. 掲載まで最短5分
        <br />
        アカウント開設から募集案件掲載まで最短5分で開始することが出来ます。
        <br />
        <br />
        2. ユニークな登録ユーザーからの紹介機能で「どんな層にも出会える」
        <br />
        「Spready」では、登録ユーザーの自己応募に加え、登録ユーザーからの紹介機能も搭載。「どんな層にも出会える」サービスです。
        <br />
        <br />
        3. マッチング人数が無制限の定額制で安心
        <br />
        一度料金が発生すると、同じ募集案件では追加で料金の支払いはございません。マッチング人数は有効期限内でしたら無制限、何度でも連絡もOKで長く関係を持つことも可能です。
        <br />
        <br />
        4. 新規事業に特化したサービスで、新規事業ノウハウが豊富
        <br />
        Spreadyは2,000件以上の新規事業に伴走してきた企業です。インタビュー前後の伴走やアイディア壁打ちなどお気軽にご相談ください！
      </span>
    ),
  },
  {
    question: <span>募集に料金は発生しますか？</span>,
    answer: (
      <span>
        募集開始は無料で何度でも可能です。
        <br />
        インタビューしたいユーザーと最初のメッセージを開始するタイミングで1枚チケットの購入が必要です。（1チケット25万円）
      </span>
    ),
  },
  {
    question: <span>インタビュー料金はいくらですか？</span>,
    answer: (
      <span>
        本サービスは、インタビューごとの課金ではなく、募集案件あたりの料金です。
        <br />
        インタビューしたいユーザーと最初のメッセージを開始するタイミングで1枚チケットの購入が必要です。（1チケット25万円）
        <br />
        1枚購入いただきましたら、その募集案件のインタビューは何人でも無制限で可能です。
      </span>
    ),
  },
  {
    question: <span>利用申し込みはどのようにできますか？</span>,
    answer: (
      <span>
        本ウェブサイトの「いますぐ募集を開始する」からアカウントを作成いただくと最短5分で募集案件を掲載することが可能です。
      </span>
    ),
  },
  {
    question: <span>申し込みから応募発生までの流れを教えてください</span>,
    answer: (
      <span>
        【アカウント開設〜募集案件公開】
        <br />
        本ウェブサイトの「いますぐ募集を開始する」からアカウントを作成いただくと最短5分で募集案件を掲載することが可能です。
        <br />
        <br />
        【応募発生】
        <br />
        募集案件を掲載すると最短当日から応募が発生いたします。掲載期間は45日間です。
      </span>
    ),
  },
  {
    question: <span>オンラインではなく、オフラインの調査を実施することは可能ですか？</span>,
    answer: (
      <span>
        オフラインの調査実施も可能です。応募者にオフラインでの実施の旨を提案いただき、実施いただけますようお願い申し上げます。
      </span>
    ),
  },
  {
    question: <span>募集対象に制限はありますか？</span>,
    answer: (
      <span>
        制限はございません。
        <br />
        機縁法を活用したプラットフォームですので、toC・toB・有識者などどんな対象者でも募集可能です。
      </span>
    ),
  },
];

export const Qa: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleOpen = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Wrapper>
      <Title>よくあるご質問</Title>
      <Container>
        {QaList.map((qa, index) => (
          <QaItem
            key={index}
            question={qa.question}
            answer={qa.answer}
            isOpen={openIndex === index}
            onClick={() => toggleOpen(index)}
          />
        ))}
      </Container>
    </Wrapper>
  );
};
