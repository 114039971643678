import { type IPublicCaseClient } from 'interfaceAdapter/gatewaySchemas/IPublicCaseClient';
import { type IPublicCaseQuery } from 'application/querySchemas/IPublicCaseQuery';
import { inject, injectable } from 'tsyringe';

@injectable()
export class PublicCaseQuery implements IPublicCaseQuery {
  constructor(
    @inject('IPublicCaseClient')
    public readonly publicCaseClient: IPublicCaseClient,
  ) {}

  public fetchCases = async ({ limit }: { limit?: number }) => {
    const { data, message } = await this.publicCaseClient.get({ limit });
    if (message) {
      throw new Error(message);
    }
    return data;
  };
}
