export const VIEWPORT_SIZES = {
  /** スマホ、タブレット縦表示 */
  SP: 799,
  /** 小さめタブレット横、PC (1280px) 用 */
  NARROW: 1280,
  /** 大きめタブレット横、PC (1440px) 用 */
  STANDARD: 1440,
  /** PC (1980px) 用 */
  WIDE: 1980,
};

/** Responsive対応 */
export const mq = (size: keyof typeof VIEWPORT_SIZES) => {
  switch (size) {
    case 'SP':
      return `@media screen and (max-width: ${VIEWPORT_SIZES.SP}px)`;
    case 'NARROW':
      return `@media screen and (min-width: ${VIEWPORT_SIZES.SP + 1}px) and (max-width: ${VIEWPORT_SIZES.NARROW}px)`;
    case 'STANDARD':
      return `@media screen and (min-width: ${VIEWPORT_SIZES.NARROW + 1}px) and (max-width: ${
        VIEWPORT_SIZES.STANDARD
      }px)`;
    case 'WIDE':
      return `@media screen and (min-width: ${VIEWPORT_SIZES.STANDARD + 1}px)`;
    default:
      return '';
  }
};
