import {
  IIntroducedUserReview,
  IntroducedUserReview,
} from 'domain/valueObjects/introducedUserReview/introducedUserReview';

export interface IIntroducedUserReviewFactory {
  buildIntroducedUserReview(review: IIntroducedUserReview): IIntroducedUserReview;
}

export class IntroducedUserReviewFactory implements IIntroducedUserReviewFactory {
  public buildIntroducedUserReview(review: IIntroducedUserReview): IIntroducedUserReview {
    return new IntroducedUserReview(review.user, review.review_text, review.created_at);
  }
}
