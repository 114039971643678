import styled from '@emotion/styled';
import ButtonImg from 'ui/images/icon_button.svg';
import { VIEWPORT_SIZES } from 'utils/responsive';
import IconNoteImg from 'ui/images/icon_note.svg';

const Button = styled.a`
  text-decoration: none;
  padding: 20px 20px 20px 0;
  border-width: 4px;
  border-radius: 50px;
  box-shadow: 0px 5px 0px 0px rgb(0, 0, 0);
  margin: 0 auto;
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  background-image: url(${ButtonImg});
  background-size: 32px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 1.2;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  width: 630px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    height: auto;
    width: 100%;
    margin-bottom: 4vw;
    font-size: 4.8vw;
    padding: 4vw 10.6vw 4vw 9.3vw;
    border-width: 1vw;
    border-radius: 10.6vw;
    box-shadow: 0px 1vw 0px 0px rgb(0, 0, 0);
    background-size: 6.1vw;
    @supports (width: 100svw) {
      margin-bottom: 4svw;
      font-size: 4.8svw;
      padding: 4svw 10.6svw 4svw 9.3svw;
      border-width: 1svw;
      border-radius: 10.6svw;
      box-shadow: 0px 1svw 0px 0px rgb(0, 0, 0);
      background-size: 6.1svw;
    }
  }

  &:hover {
    transform: translateY(4px);
    box-shadow: 0px 1px 0px 0px rgb(0, 0, 0);
    transition:
      transform 0.3s,
      box-shadow 0.3s;
  }
`;

const IconNote = styled.img`
  width: 26px;
  margin-right: 10px;
`;

export const DocDownloadButton: React.FC = () => {
  return (
    <Button href="https://client.spready.jp/document">
      <IconNote src={IconNoteImg} alt="資料ダウンロード" />
      資料ダウンロード
    </Button>
  );
};
