import styled from '@emotion/styled';
import ProcessImg from 'ui/images/process.png';
import ProcessSpImg from 'ui/images/process_sp.png';
import { RegistrationButton } from 'ui/components/elements/Button/RegistrationButton';
import { VIEWPORT_SIZES } from 'utils/responsive';
import { Only } from 'ui/components/elements/Only/Only';

const Wrapper = styled.div`
  padding: 20px 0 50px 0;
  text-align: center;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 48px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 3.2vw;
    line-height: 1.2;
    margin-bottom: 1.8vw;
    @supports (width: 100svw) {
      margin-bottom: 1.8svw;
      font-size: 3.2svw;
    }
  }
`;

const YellowBorderText = styled.div`
  font-weight: var(--font-extra-bold);
  position: relative;
  width: 705px;
  margin: 0 auto 30px auto;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 18px;
    background-color: var(--primary);
    z-index: -1;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: fit-content;
    margin-bottom: 2vw;

    @supports (width: 100svw) {
      margin-bottom: 2svw;
    }

    &:after {
      bottom: -4px;
      height: 2.67vw;
      width: calc(100% + 2.67vw);
      margin-left: -1.34vw;

      @supports (width: 100svw) {
        height: 2.67svw;

        width: calc(100% + 2.67svw);
        margin-left: -1.34svw;
      }
    }
  }
`;

const YellowBorderTextXXX = styled(YellowBorderText)`
  font-weight: var(--font-extra-bold);
  font-size: var(--font-xxx-large);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 1.2;
    @supports (width: 100svw) {
      font-size: 4.27svw;
    }
  }
`;

const YellowBorderTextXXXX = styled(YellowBorderText)`
  font-weight: var(--font-extra-bold);
  font-size: var(--font-xxxx-large);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 5.78vw;
    line-height: 1.2;
    @supports (width: 100svw) {
      font-size: 5.78svw;
    }
  }
`;

const ProcessPc = styled.img`
  width: 1026px;
  margin: 0 auto 16px auto;
`;

const ProcessSp = styled.img`
  width: 91.47vw;
  margin: 1vw auto 3vw 3.2vw;

  @supports (width: 100svw) {
    width: 91.47svw;
    margin: 1svw auto 3svw 3.2svw;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    margin: 0 auto;
    @supports (width: 100svw) {
      width: 91.47svw;
    }
  }
`;

export const BusinessValidationProcess: React.FC = () => {
  return (
    <Wrapper>
      <div>
        <Text>Spreadyであれば、新規事業には欠かせない</Text>
        <Only pc>
          <YellowBorderTextXXX>事業検証やユーザーヒアリングが 簡単に行えます</YellowBorderTextXXX>
        </Only>
        <Only sp>
          <YellowBorderTextXXX>事業検証やユーザーヒアリングが</YellowBorderTextXXX>
          <YellowBorderTextXXX>簡単に行えます</YellowBorderTextXXX>
        </Only>
      </div>
      <Only pc>
        <ProcessPc src={ProcessImg} alt="プロセス" />
      </Only>
      <Only sp>
        <ProcessSp src={ProcessSpImg} alt="プロセス" />
      </Only>
      <YellowBorderTextXXXX>すべて、Spreadyで解決できます！</YellowBorderTextXXXX>
      <ButtonContainer>
        <RegistrationButton />
      </ButtonContainer>
    </Wrapper>
  );
};
