import { useState, useEffect, useContext, useCallback } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicInfoQuery } from 'interfaceAdapter/queries/PublicInfo';

export const useAchievements = () => {
  const diContainer = useContext(diContainerContext);
  const publicInfoQuery = diContainer.resolve(PublicInfoQuery);
  const [castCount, setCastCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);

  const fetchPublicStats = useCallback(async () => {
    const fetchedPublicStats = await publicInfoQuery.fetchStats();
    setCastCount(fetchedPublicStats.cast_count);
    setCompanyCount(fetchedPublicStats.company_count);
    setSessionCount(fetchedPublicStats.session_count);
  }, [publicInfoQuery]);

  useEffect(() => {
    fetchPublicStats();
  }, [fetchPublicStats]);

  return {
    castCount,
    companyCount,
    sessionCount,
  };
};
