import { useRef } from 'react';
import styled from '@emotion/styled';
import { LpHeader } from 'ui/components/panes/Header/LpHeader';
import { MainTitle } from 'ui/components/panes/MainTitle/MainTitle';
import { Achievements } from 'ui/components/panes/Achievements/Achievements';
import { BusinessValidationProcess } from 'ui/components/panes/BusinessValidationProcess/BusinessValidationProcess';
import { Features } from 'ui/components/panes/Features/Features';
import { ServiceCapabilities } from 'ui/components/panes/ServiceCapabilities/ServiceCapabilities';
import { WhyChooseUs } from 'ui/components/panes/WhyChooseUs/WhyChooseUs';
import { Charge } from 'ui/components/panes/Charge/Charge';
import { Sessions } from 'ui/components/panes/Sessions/Sessions';
import { Users } from 'ui/components/panes/Users/Users';
import { CaseStudies } from 'ui/components/panes/CaseStudies/CaseStudies';
import { Qa } from 'ui/components/panes/Qa/Qa';
import { Footer } from 'ui/components/panes/Footer/Footer';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: var(--height-top-header);
  overflow-y: auto;
  overflow-x: hidden;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

export const Lp: React.FC = () => {
  const scrollableElm = useRef<HTMLDivElement | null>(null);
  return (
    <Container ref={scrollableElm}>
      <LpHeader scrollableElm={scrollableElm} />
      <MainTitle />
      <Achievements />
      <BusinessValidationProcess />
      <Features />
      <ServiceCapabilities />
      <WhyChooseUs />
      <Charge />
      <Sessions />
      <Users />
      <CaseStudies />
      <Qa />
      <Footer scrollableElm={scrollableElm} />
    </Container>
  );
};
