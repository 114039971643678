import styled from '@emotion/styled';
import { VIEWPORT_SIZES } from 'utils/responsive';

interface Props {
  pc?: boolean;
  sp?: boolean;
}

const Wrapper = styled.span<Props>`
  display: none;

  ${({ pc }) =>
    pc &&
    `
        display: inline;
        @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
            display: none;
        }
    `}

  ${({ sp }) =>
    sp &&
    `
        @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
            display: inline;
        }
    `}
`;

/**
 * デバイスサイズによる表示制御コンポーネント
 */
export const Only: React.FC<React.PropsWithChildren<Props>> = ({ pc, sp, children }) => {
  return (
    <Wrapper pc={pc} sp={sp}>
      {children}
    </Wrapper>
  );
};
