import styled from '@emotion/styled';
import Feature1Img from 'ui/images/feature_1.png';
import Feature2Img from 'ui/images/feature_2.png';
import Feature3Img from 'ui/images/feature_3.png';
import Feature4Img from 'ui/images/feature_4.png';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 1026px;
  margin: 50px auto 0 auto;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    gap: 8.53vw;
    margin-top: 8.53vw;
    @supports (width: 100svw) {
      width: 91.47svw;
      gap: 8.53svw;
      margin-top: 8.53svw;
    }
  }
`;

const Card = styled.div`
  background-color: var(--white);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: left;
`;

const WideCard = styled(Card)`
  width: 100%;

  img {
    width: 360px;

    @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
      width: 81.07vw;

      @supports (width: 100svw) {
        width: 81.07svw;
      }
    }
  }
`;

const NarrowCardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    flex-direction: column;
    gap: 8.53vw;
    @supports (width: 100svw) {
      gap: 8.53svw;
    }
  }
`;

const NarrowCard = styled(Card)`
  flex: 1;
  padding: 16px;
  height: 100%;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
  }
`;

const NumberLabel = styled.div`
  width: 120px;
  border-radius: 8px;
  border: 3px solid var(--black);
  color: var(--white);
  font-size: var(--font-xx-large);
  font-weight: var(--font-extra-bold);
  background-color: var(--accent);
  text-align: center;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 32vw;
    height: 9.6vw;
    font-size: 5.87vw;
    display: flex;
    align-items: center;
    justify-content: center;

    @supports (width: 100svw) {
      width: 32svw;
      height: 9.6svw;
      font-size: 5.87svw;
    }
  }
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const SubTitleXX = styled.div`
  font-size: var(--font-xx-large);
  font-weight: var(--font-extra-bold);
  line-height: 27px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 5.87vw;
    line-height: 8.8vw;
    @supports (width: 100svw) {
      font-size: 5.87svw;
      line-height: 8.8svw;
    }
  }
`;

const SubTitleXLarge = styled.div`
  font-size: var(--font-x-large);
  font-weight: ${(props: { fontWeight?: string }) => props.fontWeight || 'var(--font-extra-bold)'};
  line-height: 27px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;

    @supports (width: 100svw) {
      font-size: 4.27vw;
      line-height: 6.4svw;
    }
  }
`;

const TextMedium = styled.div`
  font-size: var(--font-medium);
  font-weight: var(--font-normal);
  line-height: 27px;

  ul {
    margin: 0;
    padding-inline-start: 25px;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 3.73vw;
    line-height: 5.6vw;
    @supports (width: 100svw) {
      font-size: 3.73svw;
      line-height: 5.6svw;
    }
  }
`;

const Feature2Picture = styled.img`
  width: 253px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 43.73vw;
    @supports (width: 100svw) {
      width: 43.73svw;
    }
  }
`;

const Feature3Picture = styled.img`
  width: 220px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 35.2vw;
    @supports (width: 100svw) {
      width: 35.2svw;
    }
  }
`;

const Feature4Picture = styled.img`
  width: 220px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 67.47vw;
    @supports (width: 100svw) {
      width: 67.47svw;
    }
  }
`;

export const Features: React.FC = () => {
  return (
    <Wrapper>
      <Title>Spreadyの4つの特徴</Title>
      <CardsWrapper>
        <WideCard>
          <NumberLabel>1</NumberLabel>
          <SubTitleXX>アカウント発行後、すぐに無料で募集案件を掲載。生成AIでかんたんに案件作成が可能です！</SubTitleXX>
          <SubTitleXLarge fontWeight="var(--font-normal)">
            アカウント発行〜募集開始まで約5分。いくつかの質問に回答するだけで、生成AIが案件の下書きを作成してくれます。
          </SubTitleXLarge>
          <img src={Feature1Img} alt="生成AIでかんたんに案件作成" />
        </WideCard>
        <NarrowCardsContainer>
          <NarrowCard>
            <NumberLabel>2</NumberLabel>
            <SubTitleXLarge>募集できる対象は個人、法人担当者、専門家などさまざま</SubTitleXLarge>
            <TextMedium>
              ユーザーからの自己応募とユニークな「ユーザからの紹介制」を採用。他サービスでは見つかりづらい「多様で」「細かな」セグメントにもアプローチが可能です。
            </TextMedium>
            <Feature2Picture src={Feature2Img} alt="募集できる対象は個人、法人担当者、専門家などさまざま" />
          </NarrowCard>
          <NarrowCard>
            <NumberLabel>3</NumberLabel>
            <SubTitleXLarge>応募者とメッセージを開始したタイミングでチケット購入</SubTitleXLarge>
            <TextMedium>
              募集は無料で掲載可能。応募者をスクリーニングして、チケットを購入するかどうか決められます。
            </TextMedium>
            <Feature3Picture src={Feature3Img} alt="チケット" />
          </NarrowCard>
          <NarrowCard>
            <NumberLabel>4</NumberLabel>
            <SubTitleXLarge>
              Spreadyを通じて得た出会いはあなたのものです。何度でも連絡OK、モニター依頼も可能です。
            </SubTitleXLarge>
            <TextMedium>
              繋がれることで以下のような事例が生まれています!
              <ul>
                <li>再インタビューの実施</li>
                <li>別の方を紹介していただく</li>
                <li>テストユーザーになっていただく</li>
                <li>初期顧客になっていただく</li>
                <li>協業の実現</li>
              </ul>
            </TextMedium>
            <Feature4Picture src={Feature4Img} alt="何度でも連絡OK" />
          </NarrowCard>
        </NarrowCardsContainer>
      </CardsWrapper>
    </Wrapper>
  );
};
