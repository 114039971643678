import styled from '@emotion/styled';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Container = styled.div`
  max-width: calc(33.33% - 16px);
  height: 400px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--white);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    min-width: 64vw;
    width: 64vw;
    height: 95.73vw;
    @supports (width: 100svw) {
      min-width: 64svw;
      width: 64svw;
      height: 95.73svw;
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;

  img {
    border-radius: 20px 20px 0 0;
    height: 186px;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    img {
      height: 42.67vw;

      @supports (width: 100svw) {
        height: 42.67svw;
      }
    }
  }
`;

const TextContainer = styled.div`
  padding: 12px;

  img {
    height: auto;
    width: 200px;
    max-width: 100%;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    img {
      width: 53.33vw;

      @supports (width: 100svw) {
        width: 53.33svw;
      }
    }
  }
`;

const Title = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-x-large);
    font-weight: var(--font-extra-bold);
    line-height: 24px;

    @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
        font-size: 4.27vw
        line-height: 6.4vw;

        @supports (width: 100svw) {
            font-size: 4.27svw
            line-height: 6.4svw;
        }
    }
`;

const Line = styled.div`
  background-color: var(--black);
  border-radius: 2px;
  flex: none;
  height: 2px;
  margin: 20px auto;
  width: 50px;
  max-width: 100%;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    margin: 4.27vw auto;
    width: 18.67vw;
    @supports (width: 100svw) {
      margin: 4.27svw auto;
      width: 18.67svw;
    }
  }
`;

export const CaseCard: React.FC<{ link: string; title: string; imgUrl: string }> = ({ link, title, imgUrl }) => {
  return (
    <Container>
      <Link href={link}>
        <img src={imgUrl} alt={title} />
        <TextContainer>
          <Title>{title}</Title>
          <Line />
        </TextContainer>
      </Link>
    </Container>
  );
};
