import styled from '@emotion/styled';
import ButtonImg from 'ui/images/icon_button.svg';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Button = styled.a`
  text-decoration: none;
  padding: 20px 20px 20px 0;
  border-width: 4px;
  border-radius: 50px;
  box-shadow: 0px 5px 0px 0px rgb(0, 0, 0);
  margin: 0 auto;
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  background-image: url(${ButtonImg});
  background-size: 32px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 1.2;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d52400;
  color: var(--white);
  cursor: pointer;
  width: 630px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    height: auto;
    width: 100%;
    font-size: 4.8vw;
    padding: 6vw 10.6vw 6vw 9.3vw;
    border-width: 1vw;
    border-radius: 10.6vw;
    box-shadow: 0px 1vw 0px 0px rgb(0, 0, 0);
    background-size: 6.1vw;
    @supports (width: 100svw) {
      font-size: 4.8svw;
      padding: 6svw 10.6svw 6svw 9.3svw;
      border-width: 1svw;
      border-radius: 10.6svw;
      box-shadow: 0px 1svw 0px 0px rgb(0, 0, 0);
      background-size: 6.1svw;
    }
  }

  &:hover {
    transform: translateY(4px);
    box-shadow: 0px 1px 0px 0px rgb(0, 0, 0);
    transition:
      transform 0.3s,
      box-shadow 0.3s;
  }
`;
const registerUrl = `${process.env.REACT_APP_SPREADY_FRONT_URL}/company/register/email/`;
export const RegistrationButton: React.FC = () => {
  return (
    <Button href={registerUrl} target="_blank" rel="noopener">
      今すぐ募集を開始する (無料)
    </Button>
  );
};
