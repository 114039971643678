import styled from '@emotion/styled';
import { CaseCard } from 'ui/components/features/CaseCard/CaseCard';
import { ImageLink } from 'ui/components/elements/ImageLink/ImageLink';
import ButtonImg from 'ui/images/icon_button.svg';
import SeminarImg from 'ui/images/seminar.png';
import { VIEWPORT_SIZES } from 'utils/responsive';
import { useCaseStudies } from './useCaseStudies';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    gap: 8.53vw;
    @supports (width: 100svw) {
      gap: 8.53svw;
    }
  }
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const Content = styled.div`
  width: 1026px;
  margin: 0 auto;
  display: flex;
  gap: 32px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
    overflow-x: auto;
    padding: 0 20px;
  }
`;

const Button = styled.a`
  text-decoration: none;
  height: 57px;
  width: 400px;
  border-width: 4px;
  border-radius: 35px;
  box-shadow: 0px 4px 0px 0px rgb(0, 0, 0);
  margin: 0 auto;
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  font-size: var(--font-xx-large);
  font-weight: bold;
  padding: 12px 24px 12px 20px;
  background-image: url(${ButtonImg});
  background-size: 32px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 1.2;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;

  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 1px 0px 0px rgb(0, 0, 0);
    transition:
      transform 0.3s,
      box-shadow 0.3s;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
    height: 11.73vw;
    font-size: 4.8vw;
    background-size: 5.87vw;
    padding: 2.13vw 6.4vw 2.13vw 4.27vw;

    @supports (width: 100svw) {
      height: 11.73svw;
      font-size: 4.8svw;
      background-size: 5.87svw;
      padding: 2.13svw 6.4svw 2.13svw 4.27svw;
    }
  }
`;

const SeminarBanner = styled.div`
  width: 700px;
  margin: 0 auto;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    margin: 0 auto;
    gap: 8.53vw;

    @supports (width: 100svw) {
      width: 91.47svw;
      gap: 8.53svw;
    }
  }
`;

export const CaseStudies: React.FC = () => {
  const { caseList } = useCaseStudies();
  return (
    <Wrapper>
      <Title>導入事例</Title>
      <Content>
        {caseList.map((item, index) => (
          <CaseCard key={index} link={item.link} title={item.title} imgUrl={item.media_content} />
        ))}
      </Content>
      <ButtonContainer>
        <Button href="https://client.spready.jp/case">導入事例をもっと見る</Button>
        <SeminarBanner>
          <ImageLink url="https://client.spready.jp/seminar" src={SeminarImg} alt="セミナー" />
        </SeminarBanner>
      </ButtonContainer>
    </Wrapper>
  );
};
