import { User } from 'domain/entities/User/User';

export interface IIntroducedUserReview {
  // user に null が入る理由
  // ユーザーの代わりに Spready 内部のスタッフがレビューコメントを追加することで、レビューのない案件をなくす運用をしている
  // その場合 User は null だがレビューコメントありとなる
  user: User | null;
  review_text: string;
  created_at: Date;
}

export class IntroducedUserReview implements IIntroducedUserReview {
  constructor(
    public readonly user: User | null,
    public readonly review_text: string,
    public readonly created_at: Date,
  ) {}
}
