import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const Container = styled(Link)`
  color: inherit;
  display: block;
`;

export const ImageLink: FC<{
  src: string;
  url: string;
  width?: string;
  height?: string;
  alt?: string;
  handler?: () => void;
}> = ({ src, url, width, height, alt, handler }) => {
  const appUrl = process.env.REACT_APP_URL;

  return (
    <Container
      to={url}
      onClick={handler}
      target={url && (url.startsWith('/') || (appUrl && url.startsWith(appUrl)) ? '_self' : '_blank')}
      rel={url && (url.startsWith('/') || (appUrl && url.startsWith(appUrl)) ? '' : 'noopener noreferrer')}
    >
      <img src={src} alt={alt} width={width} height={height} />
    </Container>
  );
};
