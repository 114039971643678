import { FC, MutableRefObject, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import { ImageLink } from 'ui/components/elements/ImageLink/ImageLink';
import { VIEWPORT_SIZES } from 'utils/responsive';
import ButtonImg from 'ui/images/icon_button.svg';
import IconNoteImg from 'ui/images/icon_note.svg';
import CloseButtonImg from 'ui/images/icon_close.svg';

interface ContainerProps {
  isScrolled: boolean;
}
interface ButtonProps {
  bgColor: string;
  fontColor: string;
  showIcon?: boolean;
}

const fadeToWhitePc = keyframes`
  from {
    background-color: transparent;
    padding: 29px 78px;
  }
  to {
    background-color: var(--white);
    padding: 20px 78px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
`;

const fadeToPrimaryPc = keyframes`
  from {
    background-color: var(--white);
    padding: 20px 78px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: transparent;
    padding: 29px 78px;
  }
`;

const fadeToWhiteSp = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--white);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
`;

const fadeToPrimarySp = keyframes`
  from {
    background-color: var(--white);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: transparent;
  }
`;

const Container = styled.div<ContainerProps>`
  z-index: var(--z-header);
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 29px 30px;
  background-color: ${(props) => (props.isScrolled ? 'var(--white)' : 'transparent')};
  font-weight: var(--font-extra-bold);

  animation: ${(props) =>
    props.isScrolled
      ? css`
          ${fadeToWhitePc} 0.2s forwards
        `
      : css`
          ${fadeToPrimaryPc} 0.2s forwards
        `};

  @media (max-width: ${VIEWPORT_SIZES.SP}px) {
    padding: 3.73vw 5.33vw;
    @supports (width: 100svw) {
      padding: 3.73svw 5.33svw;
    }

    animation: ${(props) =>
      props.isScrolled
        ? css`
            ${fadeToWhiteSp} 0.2s forwards
          `
        : css`
            ${fadeToPrimarySp} 0.2s forwards
          `};
  }
`;

const Logo = styled.div`
  width: 136px;
  img {
    width: 135px;

    @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
      width: 24vw;
      @supports (width: 100svw) {
        width: 24svw;
      }
      height: auto;
    }
  }

  a {
    width: fit-content;
  }
`;

const NavContainer = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    display: none;
  }
`;

const NavContent = styled.a`
  text-decoration: none;
  margin-right: 35px;
  font-size: 14px;
  font-weight: var(--font-extra-bold);
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid var(--black);
  }

  a {
    font-size: 14px;
  }
`;

const PcMenuContainer = styled.div`
  display: flex;
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    display: none;
  }
`;

const LoginUl = styled.ul`
  margin: auto 0;
  display: flex;
  align-items: center;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    padding: 0 10vw 0;
    margin-bottom: 5.3vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @supports (width: 100svw) {
      padding: 0 10svw 0;
      margin-bottom: 5.3svw;
    }
  }
`;

const SpMenuButton = styled.div`
  width: 5.8vw;
  height: 2.9vw;
  @supports (width: 100svw) {
    width: 5.8svw;
    height: 2.9svw;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  margin: auto 0;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--black);
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    display: flex;
  }
`;

const Button = styled.a<ButtonProps>`
  text-decoration: none;
  height: 50px;
  padding: 8px 50px 8px 16px;
  border-width: 2px;
  border-radius: 25px;
  box-shadow: 0px 3px 0px 0px rgb(0, 0, 0);
  margin-right: 10px;
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  font-size: 14px;
  ${(props) =>
    props.showIcon
      ? `
         padding: 8px 50px 8px 16px;
    background-image: url(${ButtonImg});
    background-size: 24px;
    background-position: right 20px center;
    background-repeat: no-repeat;
    `
      : ' padding: 8px 16px;'}
  text-align: center;
  line-height: 1.2;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: { bgColor: string }) => props.bgColor};
  color: ${(props: { fontColor: string }) => props.fontColor};
  cursor: pointer;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    height: auto;
    width: 100%;
    margin-bottom: 4vw;
    font-size: 4.1vw;
    font-weight: var(--font-extra-bold);
    padding: 4vw 10.6vw 4vw 9.3vw;
    border-width: 1vw;
    border-radius: 10.6vw;
    box-shadow: 0px 1vw 0px 0px rgb(0, 0, 0);
    background-size: 6.1vw;
    @supports (width: 100svw) {
      margin-bottom: 4svw;
      font-size: 4.1svw;
      padding: 4svw 10.6svw 4svw 9.3svw;
      border-width: 1svw;
      border-radius: 10.6svw;
      box-shadow: 0px 1svw 0px 0px rgb(0, 0, 0);
      background-size: 6.1svw;
    }
  }

  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 1px 0px 0px rgb(0, 0, 0);
    transition:
      transform 0.3s,
      box-shadow 0.3s;
  }
`;

const SpMenuContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: var(--primary);
  z-index: var(--z-header);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    display: block;
  }
`;

const SpMenu = styled.div`
  position: relative;
  height: 100%;
  min-height: 168vw;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 4vw;
  right: 5.6vw;
  @supports (width: 100svw) {
    top: 4svw;
    right: 5.6svw;
  }
  cursor: pointer;

  img {
    width: 5.86vw;
    height: 5.86vw;
    @supports (width: 100svw) {
      width: 5.86svw;
      height: 5.86svw;
    }
  }
`;

const SpMenuNav = styled.div`
  padding: 13.3vw 17.3vw 0;
  margin-bottom: 5vw;
  display: flex;
  flex-direction: column;

  @supports (width: 100svw) {
    padding: 13.3svw 17.3svw 0;
    margin-bottom: 5svw;
  }

  ul {
    padding: 0;
    margin: 5.3vw 0 6.7vw;
    @supports (width: 100svw) {
      margin: 5.3svw 0 6.7svw;
    }
    display: block;
    padding-inline-start: 40px;
  }

  li {
    margin-bottom: 4vw;
    font-size: 5.6vw;
    @supports (width: 100svw) {
      margin-bottom: 4svw;
      font-size: 5.6svw;
    }
  }
`;

const SpMenuBoldLink = styled.a`
  text-decoration: none;
  display: inline-block;
  font-size: 5.6vw;
  margin-bottom: 5.3vw;
  font-weight: var(--font-extra-bold);
  @supports (width: 100svw) {
    font-size: 5.6svw;
    margin-bottom: 5.3svw;
  }
  cursor: pointer;
`;

const IconNote = styled.img`
  width: 5.33vw;
  margin-right: 10px;

  @supports (width: 100svw) {
    width: 5.33svw;
  }
`;

const loginUrl = `${process.env.REACT_APP_SPREADY_FRONT_URL}/company/`;
const registerUrl = `${process.env.REACT_APP_SPREADY_FRONT_URL}/company/register/email/`;
const serviceUrl = 'https://client.spready.jp/service';
const caseUrl = 'https://client.spready.jp/case';
const seminarUrl = 'https://client.spready.jp/seminar';
const columnUrl = 'https://client.spready.jp/column';

export const LpHeader: FC<{ scrollableElm?: MutableRefObject<HTMLDivElement | null> }> = ({ scrollableElm }) => {
  const [isSpMenuOpen, setIsSpMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollableElm?.current;

      if (container) {
        setIsScrolled(container.scrollTop > 0);
      }
    };

    const container = scrollableElm?.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollableElm]);

  return (
    <header>
      <Container isScrolled={isScrolled}>
        <Logo>
          <ImageLink url="/" src="https://spready.s3-ap-northeast-1.amazonaws.com/static/new_logo_spready.svg" />
        </Logo>
        <SpMenuButton
          onClick={() => {
            setIsSpMenuOpen(!isSpMenuOpen);
          }}
        >
          <span></span>
          <span></span>
        </SpMenuButton>
        <PcMenuContainer>
          <NavContainer>
            <NavContent href={serviceUrl}>サービス一覧</NavContent>
            <NavContent href={caseUrl}>導入事例</NavContent>
            <NavContent href={seminarUrl}>セミナー</NavContent>
            <NavContent href={columnUrl}>コラム</NavContent>
          </NavContainer>
          <LoginUl>
            <Button
              showIcon
              bgColor={'var(--white)'}
              fontColor={'var(--black)'}
              href="https://client.spready.jp/document"
            >
              資料ダウンロード
            </Button>
            <Button
              href={registerUrl}
              target="_blank"
              rel="noopener"
              showIcon
              bgColor={'#d52400'}
              fontColor={'var(--white)'}
            >
              募集を開始する
              <br />
              (無料)
            </Button>
            <Button href={loginUrl} target="_blank" rel="noopener" bgColor={'#ED5332'} fontColor={'var(--white)'}>
              ログイン
            </Button>
          </LoginUl>
        </PcMenuContainer>
      </Container>
      {isSpMenuOpen && (
        <SpMenuContainer>
          <SpMenu>
            <CloseButton>
              <img alt="閉じる" src={CloseButtonImg} onClick={() => setIsSpMenuOpen(false)} />
            </CloseButton>
            <SpMenuNav>
              <SpMenuBoldLink href={serviceUrl}>・サービス一覧</SpMenuBoldLink>
              <SpMenuBoldLink href={caseUrl}>・導入事例</SpMenuBoldLink>
              <SpMenuBoldLink href={seminarUrl}>・セミナー</SpMenuBoldLink>
              <SpMenuBoldLink href={columnUrl}>・コラム</SpMenuBoldLink>
            </SpMenuNav>
            <LoginUl>
              <Button
                href="https://client.spready.jp/document"
                showIcon
                bgColor={'var(--white)'}
                fontColor={'var(--black)'}
              >
                <IconNote src={IconNoteImg} alt="資料ダウンロード" />
                資料ダウンロード
              </Button>
              <Button
                href={registerUrl}
                target="_blank"
                rel="noopener"
                showIcon
                bgColor={'#d52400'}
                fontColor={'var(--white)'}
              >
                募集を開始する (無料)
              </Button>
              <Button href={loginUrl} target="_blank" rel="noopener" bgColor={'#ED5332'} fontColor={'var(--white)'}>
                ログイン
              </Button>
            </LoginUl>
          </SpMenu>
        </SpMenuContainer>
      )}
    </header>
  );
};
