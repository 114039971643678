import styled from '@emotion/styled';
import AboutServiceImg from 'ui/images/about_service.png';
import AboutServiceSpImg from 'ui/images/about_service_sp.png';
import { Only } from 'ui/components/elements/Only/Only';
import { DocDownloadButton } from 'ui/components/elements/Button/DocDownloadButton';
import { RegistrationButton } from 'ui/components/elements/Button/RegistrationButton';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Wrapper = styled.div`
  background: var(--primary);
  padding-top: var(--height-header);
  padding-bottom: 50px;
  text-align: center;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    padding-top: 22vw;

    @supports (width: 100svw) {
      padding-top: 22svw;
    }
  }
`;

const TitleText = styled.div`
  padding-top: 20px;
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  img {
    width: 340px;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    gap: 6vw;
    img {
      width: 57.33vw;
    }

    @supports (width: 100svw) {
      font-size: 4.27svw;
      gap: 6svw;
      img {
        width: 57.33svw;
      }
    }
  }
`;

const AboutServicePc = styled.img`
  width: 1093px;
  margin-top: -20px;
`;

const AboutServiceSp = styled.img`
  width: 100%;
  margin-top: 20px;
`;

const ButtonsContainer = styled.div`
  width: 635px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    padding-top: 0;

    @supports (width: 100svw) {
      width: 91.47svw;
    }
  }
`;

const ButtonWithBubble = styled.div`
  position: relative;
  display: inline-block;
`;

const SpeechBubble = styled.div`
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  color: white;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  white-space: nowrap;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
    z-index: 2;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.8vw;
    line-height: 7.2vw;
    padding: 12px 20px;
    top: -36px;
    @supports (width: 100svw) {
      font-size: 4.8svw;
      line-height: 7.2svw;
    }
  }
`;

export const MainTitle: React.FC = () => {
  return (
    <Wrapper>
      <TitleText>
        "繋がれる”インタビュープラットフォーム
        <img src="https://spready.s3-ap-northeast-1.amazonaws.com/static/new_logo_spready.svg" alt="spready" />
      </TitleText>
      <Only pc>
        <AboutServicePc src={AboutServiceImg} alt="about_service" />
      </Only>
      <Only sp>
        <AboutServiceSp src={AboutServiceSpImg} alt="about_service" />
      </Only>
      <ButtonsContainer>
        <ButtonWithBubble>
          <SpeechBubble>募集開始無料！</SpeechBubble>
          <RegistrationButton />
        </ButtonWithBubble>
        <DocDownloadButton />
      </ButtonsContainer>
    </Wrapper>
  );
};
