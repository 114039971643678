import { type IPublicInfoQuery } from 'application/querySchemas/IPublicInfoQuery';
import { type IPublicStatsClient } from 'interfaceAdapter/gatewaySchemas/IPublicStatsClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class PublicInfoQuery implements IPublicInfoQuery {
  constructor(
    @inject('IPublicStatsClient')
    public readonly publicStatsClient: IPublicStatsClient,
  ) {}

  public fetchStats = async () => {
    const { data, message } = await this.publicStatsClient.get();
    if (message) {
      throw new Error(message);
    }
    return data;
  };
}
