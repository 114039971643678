import { Global, css } from '@emotion/react';

const variables = css`
  :root {
    /* Font */
    --font-xx-small: 8px;
    --font-x-small: 9px;
    --font-small: 10px;
    --font-default: 12px;
    --font-medium: 14px;
    --font-large: 16px;
    --font-x-large: 18px;
    --font-xx-large: 22px;
    --font-xxx-large: 28px;
    --font-xxxx-large: 32px;
    --font-xxxxx-large: 36px;
    --font-xxxxxx-large: 42px;
    --font-extra-bold: 900;
    --font-bold: 600;
    --font-normal: 400;

    /* Color */
    --primary: #fced35;
    --primary-pale: #fef7ae;
    --primary-paler: #fcf9d9;
    --primary-strong: #ffd500;
    --primary-dark: #f1a204;
    --accent: #0357fc;
    --accent-pale: #aec9fe;
    --accent-paler: #d7e4ff;
    --accent-strong: #00a3f5;
    --accent-dark: #0245ca;
    --warn: #ec1313;
    --warn-pale: #f9b4b4;
    --warn-paler: #fcd9d9;
    --warn-dark: #bd0f0f;
    --gray-light-3: #f4f4f4;
    --gray-light-2: #e8e8e8;
    --gray-light-1: #d4d4d4;
    --gray: #bebebe;
    --gray-dark-1: #a5a5a5;
    --gray-dark-2: #828282;
    --gray-dark-3: #323232;
    --gray-dark-4: #525252;
    --gray-black: #1e1e1e;
    --secondary: #f17a04;
    --secondary-pale: #fed6af;
    --secondary-paler: #feebd7;
    --secondary-dark: #e24e03;
    --green: #03a600;
    --white: #ffffff;
    --black: #000000;

    /* z-index */
    --z-header: 100;

    /* Height */
    --height-header: 108px;
  }
`;

export const Variables: React.FC = () => {
  return (
    <Global
      styles={css`
        ${variables}
      `}
    />
  );
};
