import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import IconArrowImg from 'ui/images/icon_arrow_blue.png';
import { IProject } from 'domain/entities/Project/Project';
import { Card } from './Card';
import styled from '@emotion/styled';
import { Only } from 'ui/components/elements/Only/Only';

const NextArrow = styled.img`
  // slickのデフォルトcssを打ち消すために!importantを使用
  width: 66px !important;
  height: 66px !important;
  position: absolute;
  right: -70px !important;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <NextArrow src={IconArrowImg} alt="矢印アイコン" />,
};

export const SessionCards: React.FC<{ projectList: IProject[] }> = ({ projectList }) => {
  return (
    <>
      <Only pc>
        <Slider {...settings}>
          {projectList.map((project, index) => (
            <Card
              key={index}
              id={project.id}
              title={project.title}
              mainPic={project.main_pic}
              companyName={project?.company?.name || ''}
            />
          ))}
        </Slider>
      </Only>
      <Only sp>
        <Container>
          {projectList.map((project, index) => (
            <Card
              key={index}
              id={project.id}
              title={project.title}
              mainPic={project.main_pic}
              companyName={project?.company?.name || ''}
            />
          ))}
        </Container>
      </Only>
    </>
  );
};
