import styled from '@emotion/styled';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Container = styled.div`
  width: 272px;
  height: 307px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--white);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    min-width: 64vw;
    width: 64vw;
    height: 81.73vw;

    @supports (width: 100svw) {
      min-width: 64svw;
      width: 64svw;
      height: 81.73svw;
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;

  img {
    border-radius: 20px 20px 0 0;
  }
`;

const TextContainer = styled.div`
  padding: 12px;
`;

const CompanyName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--font-medium);
  font-weight: var(--font-extra-bold);
`;

const Title = styled.div`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-large);
  font-weight: var(--font-normal);
  line-height: 24px;
`;

export const Card: React.FC<{ id: number; title: string; mainPic: string; companyName: string }> = ({
  id,
  title,
  mainPic,
  companyName,
}) => {
  const projectUrl = `${process.env.REACT_APP_SPREADY_FRONT_URL}/projects/${id}`;
  return (
    <Container>
      <Link target="_blank" rel="noopener" href={projectUrl}>
        <img src={mainPic} alt={title} />
        <TextContainer>
          <CompanyName>{companyName}</CompanyName>
          <Title>{title}</Title>
        </TextContainer>
      </Link>
    </Container>
  );
};
