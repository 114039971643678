import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicCaseQuery } from 'interfaceAdapter/queries/PublicCase';
import { ICase } from 'domain/entities/Case/Case';

export const useCaseStudies = () => {
  const diContainer = useContext(diContainerContext);
  const publicCaseQueryRef = useRef(diContainer.resolve(PublicCaseQuery));
  const [caseList, setCaseList] = useState<ICase[]>([]);

  const fetch = useCallback(async () => {
    const fetchedCases = await publicCaseQueryRef.current.fetchCases({});
    setCaseList(fetchedCases);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return {
    caseList,
  };
};
