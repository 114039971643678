import styled from '@emotion/styled';
import { FC, MutableRefObject } from 'react';
import { CustomLink } from 'ui/components/elements/CustomLink/CustomLink';
import { ImageLink } from 'ui/components/elements/ImageLink/ImageLink';
import ButtonImg from 'ui/images/footer/btn_to_top.png';
import FbImg from 'ui/images/footer/logo_facebook.svg';
import NoteImg from 'ui/images/footer/note_logo.svg';
import XImg from 'ui/images/footer/x_logo.svg';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Container = styled.div`
  padding: 50px 0 28px;
  position: relative;
  background: var(--black);
  font-size: var(--font-medium);
  display: flex;
  flex-direction: column;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    padding: 10.67vw 4.27vw;
    gap: 8.53vw;

    @supports (width: 100svw) {
      padding: 10.67svw 4.27svw;
      gap: 8.53svw;
    }
  }
`;

const TopButton = styled.div`
  position: absolute;
  top: 36px;
  right: 60px;
  cursor: pointer;

  img {
    width: 68px;
    height: 68px;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    right: 30px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100vw;
  @supports (width: 100svw) {
    width: 100svw;
  }
  padding: 0 60px;
  margin: 0 auto;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 76svw;
    padding: 0;

    @supports (width: 100svw) {
      width: 76svw;
    }
  }
`;

const LinkContents = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  gap: 20px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    gap: 8.53vw;
    @supports (width: 100svw) {
      gap: 8.53svw;
    }
  }
`;

const SnsUl = styled.ul`
  display: flex;
  align-items: center;
  padding-inline-start: 0;

  li {
    margin-right: 28px;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

const LinkUl = styled.ul`
  display: flex;
  margin-block-end: 0;
  padding-inline-start: 0;
  font-size: 16px;
  li {
    margin-right: 14px;
    color: var(--white);
    list-style: none;
  }
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    font-size: 3.73vw;
    font-weight: var(--font-extra-bold);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 3.2vw;
    @supports (width: 100svw) {
      font-size: 3.73svw;
      gap: 3.2svw;
    }
  }
`;

const Copyright = styled.div`
  font-weight: var(--font-extra-bold);
  color: var(--white);
  margin-left: auto;
  padding-right: 60px;
  width: fit-content;

  small {
    font-size: 80%;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    margin: auto;

    small {
      font-size: 3.73vw;
      font-weight: var(--font-normal);
      @supports (width: 100svw) {
        font-size: 3.73svw;
      }
    }
  }
`;

const List = styled.div`
  display: flex;
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 100%;
  }
`;

const toTop = (scrollableElm?: MutableRefObject<HTMLDivElement | null>) => {
  if (!scrollableElm || scrollableElm.current === undefined) return;

  const elm = scrollableElm?.current;
  if (elm) {
    const scrollToTop = () => {
      const currentPosition = elm?.scrollTop;
      if (currentPosition > 0) {
        const newPosition = currentPosition - currentPosition / 10;
        elm.scrollTop = newPosition;
        requestAnimationFrame(scrollToTop);
      }
    };
    scrollToTop();
  }
};

export const Info: FC<{ scrollableElm?: MutableRefObject<HTMLDivElement | null> }> = ({ scrollableElm }) => {
  return (
    <Container>
      <Footer>
        <LinkContents>
          <SnsUl>
            <li>
              <ImageLink src={XImg} url="https://twitter.com/spready_jp" alt="xリンク" />
            </li>
            <li>
              <ImageLink src={FbImg} url="https://www.facebook.com/spready.jp/" alt="facebookリンク" />
            </li>
            <li>
              <ImageLink src={NoteImg} url="https://note.com/spready/" alt="noteリンク" />
            </li>
          </SnsUl>
          <LinkUl>
            <List>
              <li>
                <CustomLink url="https://spready.jp/info/terms/" target="_blank" text="利用規約" />
              </li>
              <li>
                <CustomLink url="https://spready.jp/info/policy/" target="_blank" text="プライバシーポリシー" />
              </li>
              <li>
                <CustomLink text="運営会社" url="https://spready.co.jp/" />
              </li>
            </List>
            <List>
              <li>
                <CustomLink url="https://client.spready.jp/inquiry" target="_blank" text="お問い合わせ" />
              </li>
              <li>
                <CustomLink
                  url="https://client.spready.jp/commerce-disclosure"
                  target="_blank"
                  text="特定商取引法に基づく表記"
                />
              </li>
            </List>
          </LinkUl>
        </LinkContents>
        <TopButton onClick={() => toTop(scrollableElm)}>
          <img src={ButtonImg} alt="トップへ" />
        </TopButton>
      </Footer>
      <Copyright>
        <small>Copyright Spready. All Rights Reserved</small>
      </Copyright>
    </Container>
  );
};
