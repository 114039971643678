import { FC } from 'react';
import { container } from 'system/registry';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { Outlet } from 'react-router-dom';

const ContextConfig: FC = () => {
  return (
    <diContainerContext.Provider value={container}>
      <Outlet />
    </diContainerContext.Provider>
  );
};

export default ContextConfig;
