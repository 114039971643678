import * as yup from 'yup';
import {
  Project,
  PROJECT_CATEGORY,
  ProjectStatus,
  SPREADY_CHECK,
  convertProjectStatusStr,
  IProject,
  ProjectStatusNumerics,
  ProjectCategory,
  SpreadyCheck,
  ProjectIssue,
  PROJECT_ISSUE,
  PROJECT_STATUS_NUMERICS,
  PROJECT_TITLE_OPINION,
  ProjectTitleOpinion,
} from 'domain/entities/Project/Project';
import { CompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import { CompanyFactory } from 'domain/entities/factories/Company';
import { ICompany } from 'domain/entities/Company/Company';
import { companySchema, IPrivacyTeamSchema } from 'domain/entities/factories/Company';
import { IntroducedUserReviewFactory } from 'domain/valueObjects/factories/IntroducedUserReview';

export const projectSchema = yup.object().shape({
  id: yup.number().required(),
  company: yup.mixed<IPrivacyTeamSchema | typeof companySchema>().required(),
  category: yup.mixed<ProjectCategory>().oneOf(Object.values(PROJECT_CATEGORY)).required().nullable(),
  issues: yup.array(yup.mixed<ProjectIssue>().oneOf(Object.values(PROJECT_ISSUE)).required()).optional(),
  title: yup.string().required(),
  target_person: yup.string().optional().nullable(),
  topic_of_interest: yup.string().optional().nullable(),
  title_complement_text: yup.string().optional().nullable(),
  title_opinion_type: yup.mixed<ProjectTitleOpinion>().oneOf(Object.values(PROJECT_TITLE_OPINION)).optional(),
  main_pic: yup.string().required(),
  targets: yup.array(yup.string().required()).required(),
  share_enable: yup.boolean().required(),
  status: yup.mixed<ProjectStatusNumerics>().oneOf(Object.values(PROJECT_STATUS_NUMERICS)).optional(),
  enable_offline_interview: yup.boolean().optional().default(false),
  casting_count: yup.number().optional(),
  summary: yup.string().default('').defined().nullable(),
  privacy: yup.boolean().optional(),
  number_of_people: yup.number().optional().nullable(),
  meet_request_count: yup.number().optional(),
  meetup_fixed_count: yup.number().optional(),
  cancel_count: yup.number().optional(),
  themes: yup.array(yup.string().required()).optional().default([]),
  match_points: yup.array(yup.string().required()),
  match_point_text: yup.string().optional().default(''),
  background: yup.string().optional().default(''),
  goal: yup.string().optional().default('').nullable(),
  outro: yup.string().optional().default('').nullable(),
  main_thumbnail: yup.string().optional(),
  isOpen: yup.boolean().optional(),
  has_self_applied: yup.boolean().optional(),
  spready_check: yup.mixed<SpreadyCheck>().oneOf(Object.values(SPREADY_CHECK)).optional(),
  start_at: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
  end_at: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
  management_users: yup.array().optional(),
  create_user: yup.object().optional(),
  chat_template_text: yup.string().nullable(),
  is_favorite: yup.boolean().optional(),
  tags: yup.array().optional(),
  matched_barometer: yup
    .object()
    .shape({
      level: yup.number().required(),
      label: yup.string().required(),
    })
    .default(undefined),
  introduced_user_reviews: yup.array().optional(),
  satisfaction: yup.number().nullable(),
  start_datetime: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
  end_datetime: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
});

export type IProjectSchema = Readonly<yup.InferType<typeof projectSchema>>;

export type BuildProjectInput = IProjectSchema & {
  status: ProjectStatusNumerics & ProjectStatus;
  number_of_people?: number | null;
};

export interface IProjectFactory {
  buildProject(data: BuildProjectInput): IProject;
}

const isCompanySchema = (data: unknown): data is yup.InferType<typeof companySchema> => {
  try {
    companySchema.validateSync(data);
    return true;
  } catch (e) {
    return false;
  }
};

// IPrivacyTeamSchema型であるか
const isPrivacyTeamSchema = (data: unknown): data is IPrivacyTeamSchema => {
  if (isCompanySchema(data) || data === null) {
    return false;
  }
  if (typeof data === 'object') {
    // IPrivacyTeamSchema は status のみ渡される
    if ('status' in data && typeof (data as { status: unknown }).status === 'number') {
      return true;
    }
  }
  return false;
};

const buildCompany = (data: unknown): ICompany | null => {
  if (isCompanySchema(data)) {
    return new CompanyFactory().build(data);
  }
  // 企業情報非公開の場合は、companyが IPrivacyTeamSchema型になる
  if (isPrivacyTeamSchema(data)) {
    return new CompanyFactory().buildPrivacy(data);
  }
  return null;
};

export class ProjectFactory implements IProjectFactory {
  public buildProject(data: BuildProjectInput): IProject {
    const thumb = data.main_pic ? data.main_pic.replace('.png', '_thumb.png') : '';
    const status = Number.isInteger(data.status)
      ? (convertProjectStatusStr(data.status as ProjectStatusNumerics) as ProjectStatus)
      : data.status;

    // start_datetimeが7日間以内
    const isNew = data.start_datetime ? Date.now() - new Date(data.start_datetime).getTime() < 86400 * 7 * 1000 : false;

    return new Project(
      data.id,
      buildCompany(data.company),
      data.category ?? PROJECT_CATEGORY.NORMAL,
      data.issues ?? [],
      data.title,
      data.main_pic,
      data.targets,
      data.share_enable,
      status,
      data.enable_offline_interview,
      data.casting_count ?? 0,
      data.meet_request_count ?? 0,
      data.meetup_fixed_count ?? 0,
      data.cancel_count ?? 0,
      data.summary,
      data.privacy ?? false,
      data.target_person,
      data.topic_of_interest,
      data.title_complement_text,
      data.title_opinion_type,
      data.number_of_people,
      data.themes,
      data.match_points,
      data.match_point_text ? data.match_point_text.replace(/\\n/g, '\n') : '',
      data.background,
      data.goal ?? '',
      data.outro ?? '',
      thumb,
      data.isOpen,
      data.has_self_applied,
      isNew,
      data.spready_check,
      data.start_at,
      data.end_at,
      data.management_users &&
        (data.management_users.map((companyUser) => new CompanyUserFactory().buildByPartial(companyUser)) ?? []),
      // @ts-ignore CompanyUserの型が違う
      data.create_user ? new CompanyUserFactory().build(data.create_user) : null,
      data.chat_template_text,
      data.is_favorite,
      data.tags,
      data.matched_barometer,
      data.introduced_user_reviews
        ? data.introduced_user_reviews.map((review) =>
            new IntroducedUserReviewFactory().buildIntroducedUserReview(review),
          )
        : [],
      data.satisfaction,
      data.start_datetime,
      data.end_datetime,
    );
  }
}
