import { FC } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ContextConfig from './ContextConfig';
import { Lp } from 'ui/pages/Lp/Lp';

const router = createBrowserRouter([
  {
    element: <ContextConfig />,
    children: [{ path: '/', element: <Lp /> }],
  },

  { path: '*', element: <Navigate to="/not_found/" replace /> },
  { path: '/not_found/', element: <>404 Not Found</> },
]);

const RouterConfig: FC = () => <RouterProvider router={router} />;

export default RouterConfig;
