import styled from '@emotion/styled';
import CompaniesImg from 'ui/images/companies.png';
import CompaniesSpImg from 'ui/images/companies_sp.png';
import { Only } from 'ui/components/elements/Only/Only';
import { VIEWPORT_SIZES } from 'utils/responsive';
import { useAchievements } from './useAchievements';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    gap: 20px;
  }
`;

const Text = styled.div`
  font-weight: var(--font-extra-bold);
  line-height: 48px;

  span {
    font-size: var(--font-xxxxxx-large);
    margin: 0 10px;

    @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
      font-size: 8.53vw;
      line-height: 1;
      @supports (width: 100svw) {
        font-size: 8.53svw;
      }
    }
  }
`;

const TextXXXLarge = styled(Text)`
  font-size: var(--font-xxx-large);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 1.2;
    margin-top: 5px;
    @supports (width: 100svw) {
      font-size: 4.27svw;
    }
  }
`;

const TextXXXXLarge = styled(Text)`
  font-size: var(--font-xxxx-large);

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 1.2;
    @supports (width: 100svw) {
      font-size: 4.27svw;
    }
  }
`;

const YellowBorderText = styled.div`
  font-weight: var(--font-extra-bold);
  font-size: var(--font-xxxx-large);
  position: relative;
  width: fit-content;
  margin: 15px auto 25px auto;

  span {
    font-size: 80px;
    margin: 0 20px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 26px;
    background-color: var(--primary);
    z-index: -1;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 5.87vw;
    line-height: 1.2;
    margin-top: 0;

    span {
      font-size: 16vw;
    }

    &:after {
      height: 3.73vw;
      bottom: 0;
    }
    @supports (width: 100svw) {
      font-size: 5.87svw;

      span {
        font-size: 16svw;
      }

      &:after {
        height: 3.73svw;
      }
    }
  }
`;

const CompaniesPc = styled.img`
  width: 1026px;
  margin: 0 auto;
`;

const CompaniesSp = styled.img`
  width: 100%;
`;

export const Achievements: React.FC = () => {
  const { castCount, companyCount, sessionCount } = useAchievements();
  return (
    <Wrapper>
      <TextXXXLarge>
        今までに<span>{companyCount.toLocaleString()}</span>社が新規事業を登録し
        <Only sp>
          <br />
        </Only>
        <span>{sessionCount.toLocaleString()}</span>件の案件が掲載
      </TextXXXLarge>
      <div>
        <TextXXXXLarge>企業とユーザーのマッチング</TextXXXXLarge>
        <YellowBorderText>
          総計<span>{castCount.toLocaleString()}</span>件
        </YellowBorderText>
      </div>
      <Only pc>
        <CompaniesPc src={CompaniesImg} alt="企業一覧" />
      </Only>
      <Only sp>
        <CompaniesSp src={CompaniesSpImg} alt="企業一覧" />
      </Only>
    </Wrapper>
  );
};
