import { PushedProjectsClient } from 'infrastructure/apiClients/PushedProjects/PushedProjects';
import { PublicStatsClient } from 'infrastructure/apiClients/PublicStats/PublicStats';
import { PublicCaseClient } from 'infrastructure/apiClients/PublicCase/PublicCase';
import { PublicProjectQuery } from 'interfaceAdapter/queries/PublicProject';
import { PublicInfoQuery } from 'interfaceAdapter/queries/PublicInfo';
import { PublicCaseQuery } from 'interfaceAdapter/queries/PublicCase';
import { ProjectFactory } from 'domain/entities/factories/Project';
import { container } from 'tsyringe';

container.register('IPushedProjectsClient', { useClass: PushedProjectsClient });
container.register('IPublicStatsClient', { useClass: PublicStatsClient });
container.register('IPublicCaseClient', { useClass: PublicCaseClient });
container.register('IPublicProjectQuery', { useClass: PublicProjectQuery });
container.register('IPublicInfoQuery', { useClass: PublicInfoQuery });
container.register('IPublicCaseQuery', { useClass: PublicCaseQuery });
container.register('IProjectFactory', { useClass: ProjectFactory });
export { container };
