import styled from '@emotion/styled';
import { ReactNode } from 'react';
import IconQ from 'ui/images/question.svg';
import IconNarrow from 'ui/images/icon_simple_narrow_blue.svg';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Container = styled.div`
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid var(--gray-dark-1);
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 0;
  cursor: pointer;
`;

const QuestionText = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-x-large);
  font-weight: var(--font-extra-bold);
  gap: 10px;
  img {
    width: 50px;
    height: 50px;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 6.4vw;
    gap: 2.4vw;
    text-align: left;
    img {
      width: 10.67vw;
      height: 10.67vw;
    }

    span {
      width: 62.67vw;
    }

    @supports (width: 100svw) {
      font-size: 4.27svw;
      line-height: 6.4svw;
      gap: 2.4svw;
      img {
        width: 10.67svw;
        height: 10.67svw;
      }

      span {
        width: 62.67svw;
      }
    }
  }
`;

const Answer = styled.div<{ isOpen: boolean }>`
  position: relative;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition:
    opacity 0.5s,
    max-height 0.5s ease;
  font-size: var(--font-large);
  line-height: 1.8;
  text-align: left;
  padding-left: 58px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 6.4vw;
    padding-left: 12vw;
    @supports (width: 100svw) {
      font-size: 4.27svw;
      line-height: 6.4svw;
      padding-left: 12svw;
    }
  }
`;

const Narrow = styled.img<{ isOpen: boolean }>`
  width: 24px;
  transition: transform 0.5s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 6.4vw;
    margin-right: 5vw;
    @supports (width: 100svw) {
      width: 6.4svw;
      margin-right: 5svw;
    }
  }
`;

export const QaItem: React.FC<{
  question: ReactNode;
  answer: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}> = ({ question, answer, isOpen, onClick }) => {
  return (
    <Container>
      <Question onClick={onClick}>
        <QuestionText>
          <img src={IconQ} alt="よくある質問" />
          {question}
        </QuestionText>
        <Narrow src={IconNarrow} alt="開閉アイコン" isOpen={isOpen} />
      </Question>
      <Answer isOpen={isOpen}>{answer}</Answer>
    </Container>
  );
};
