import { FC, MutableRefObject } from 'react';
import styled from '@emotion/styled';
import Person1Img from 'ui/images/footer/person1.png';
import Person2Img from 'ui/images/footer/person2.png';
import LogoImg from 'ui/images/spready_logo.png';
import { DocDownloadButton } from 'ui/components/elements/Button/DocDownloadButton';
import { RegistrationButton } from 'ui/components/elements/Button/RegistrationButton';
import { Info } from './Info';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Wrapper = styled.div`
  background-color: var(--primary);
  position: relative;
  text-align: center;
  padding-top: 40px;
  min-height: 490px;

  &::before {
    width: 140px;
    height: 400px;
    background-size: 140px 400px;
    content: '';
    display: block;
    position: absolute;
    left: 105px;
    bottom: 0px;
    background-image: url(${Person1Img});
  }

  &::after {
    right: 0px;
    width: 350px;
    height: 397px;
    background-size: 350px 397px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    background-image: url(${Person2Img});
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    min-height: 130.67vw;

    &::before {
      width: 27.27vw;
      height: 78.93vw;
      background-size: 27.27vw 78.93vw;
      background-repeat: no-repeat;
      left: 4.27vw;
      bottom: -40vw;
    }

    &::after {
      right: -16vw;
      width: 69.33vw;
      height: 78.4vw;
      background-size: 69.33vw 78.4vw;
      background-repeat: no-repeat;
      bottom: -40vw;
    }

    @supports (width: 100svw) {
      min-height: 130.67svw;

      &::before {
        width: 27.27svw;
        height: 78.93svw;
        background-size: 27.27svw 78.93svw;
        left: 4.27svw;
        bottom: -40svw;
      }

      &::after {
        right: -16svw;
        width: 69.33svw;
        height: 78.4svw;
        background-size: 69.33svw 78.4svw;
        bottom: -40svw;
      }
    }
  }
`;

const SubTitle = styled.div`
  font-size: var(--font-xxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 42px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.8vw;
    line-height: 7.2vw;

    @supports (width: 100svw) {
      font-size: 4.8svw;
      line-height: 7.2svw;
    }
  }
`;

const Logo = styled.img`
  width: 360px;
  margin: 0 auto;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 64vw;
    margin: 5vw auto 8vw auto;
    @supports (width: 100svw) {
      width: 64svw;
      margin: 5svw auto 8svw auto;
    }
  }
`;

const ButtonsContainer = styled.div`
  width: 635px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    margin: 0 auto;
    gap: 5vw;
    @supports (width: 100svw) {
      width: 91.47svw;
      gap: 5svw;
    }
  }
`;

export const Footer: FC<{ scrollableElm?: MutableRefObject<HTMLDivElement | null> }> = ({ scrollableElm }) => {
  return (
    <>
      <Wrapper>
        <SubTitle>事業開発に必要な出会いなら</SubTitle>
        <Logo src={LogoImg} alt="Spready" />
        <ButtonsContainer>
          <RegistrationButton />
          <DocDownloadButton />
        </ButtonsContainer>
      </Wrapper>
      <Info scrollableElm={scrollableElm} />
    </>
  );
};
