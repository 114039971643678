import styled from '@emotion/styled';
import WhyChooseUsImg from 'ui/images/why_choose_us.png';
import WhyChooseUsSpImg from 'ui/images/why_choose_us_sp.png';
import { VIEWPORT_SIZES } from 'utils/responsive';
import { Only } from 'ui/components/elements/Only/Only';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 16px;
  display: flex;
  flex-direction: column;

  img {
    width: 1026px;
    margin: 0 auto;
  }

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    img {
      width: 91.47vw;
      @supports (width: 100svw) {
        width: 91.47svw;
      }
    }
  }
`;

const Text = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;
  margin-bottom: 20px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

export const WhyChooseUs: React.FC = () => {
  return (
    <Wrapper>
      <Text>Spreadyが選ばれる理由</Text>
      <Only pc>
        <img src={WhyChooseUsImg} alt="Spreadyが選ばれる理由" />
      </Only>
      <Only sp>
        <img src={WhyChooseUsSpImg} alt="Spreadyが選ばれる理由" />
      </Only>
    </Wrapper>
  );
};
