import styled from '@emotion/styled';
import Capability1Img from 'ui/images/capability_1.png';
import Capability2Img from 'ui/images/capability_2.png';
import Capability3Img from 'ui/images/capability_3.png';
import Capability4Img from 'ui/images/capability_4.png';
import Capability5Img from 'ui/images/capability_5.png';
import { RegistrationButton } from 'ui/components/elements/Button/RegistrationButton';
import { VIEWPORT_SIZES } from 'utils/responsive';

const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  gap: 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-light-3);
`;

const Title = styled.div`
  font-size: var(--font-xxxxxx-large);
  font-weight: var(--font-extra-bold);
  line-height: 63px;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 7.47vw;
    line-height: 11.2vw;
    @supports (width: 100svw) {
      font-size: 7.47svw;
      line-height: 11.2svw;
    }
  }
`;

const Text = styled.div`
  font-size: var(--font-large);
  font-weight: var(--font-extra-bold);
  line-height: 24px;
  text-align: center;

  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    font-size: 4.27vw;
    line-height: 6.4vw;
    @supports (width: 100svw) {
      font-size: 4.27svw;
      line-height: 6.4svw;
    }
  }
`;

const CardsWrapper = styled.div`
    display: flex;
    gap: 20px;
    width: 1026px;
    margin: 20px auto;

    img {
        width: 160px;
    }

    @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
        width: 87.47vw;
        flex-wrap: wrap;

        div:first-of-type {
            width: 100%;

            img {
                48.27vw;
                @supports (width: 100svw) {
                    48.27svw;
                }
            }
        }

        div:not(:first-of-type) {
            width: calc(50% - 10px);

            img {
                width: 34.93vw;
                @supports (width: 100svw) {
                    width: 34.93svw;
                }
            }
        }

        @supports (width: 100svw) {
            width: 87.47svw;
        }
    }
`;

const Card = styled.div`
  background-color: var(--white);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: left;
`;

const ButtonContainer = styled.div`
  @media (max-width: ${VIEWPORT_SIZES.NARROW}px) {
    width: 91.47vw;
    margin: 0 auto;
    @supports (width: 100svw) {
      width: 91.47svw;
    }
  }
`;

export const ServiceCapabilities: React.FC = () => {
  return (
    <Wrapper>
      <Title>Spreadyの機能</Title>
      <CardsWrapper>
        <Card>
          <Text>生成AIでらくらく案件作成サポート機能</Text>
          <img src={Capability1Img} alt="生成AIでらくらく案件作成サポート機能" />
        </Card>
        <Card>
          <Text>一目で会うべきかわかるスクリーニング機能</Text>
          <img src={Capability2Img} alt="一目で会うべきかわかるスクリーニング機能" />
        </Card>
        <Card>
          <Text>1チケットで会える人数・回数は無制限</Text>
          <img src={Capability3Img} alt="1チケットで会える人数・回数は無制限" />
        </Card>
        <Card>
          <Text>お支払いは請求書・クレジットカード可能</Text>
          <img src={Capability4Img} alt="お支払いは請求書・クレジットカード可能" />
        </Card>
        <Card>
          <Text>社名非公開機能搭載（オプション料金）</Text>
          <img src={Capability5Img} alt="社名非公開機能搭載（オプション料金）" />
        </Card>
      </CardsWrapper>
      <ButtonContainer>
        <RegistrationButton />
      </ButtonContainer>
    </Wrapper>
  );
};
