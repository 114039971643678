import fixture from './PublicCase.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import { IGetInput, IGetResponse, IPublicCaseClient } from 'interfaceAdapter/gatewaySchemas/IPublicCaseClient';

export class PublicCaseClient implements IPublicCaseClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { limit = 3, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
    };
    const PATH = `${API_LOCATION_SPREADY}/external/public_cases?limit=${limit}`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('事例の取得に失敗しました');
    }
  }
}
