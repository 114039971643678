interface Plan {
  id?: number;
  key?: string;
  display_name?: string;
  initial_cost?: number;
  price?: number;
  number_of_published_projects?: number;
}

export class OptionPlan {
  public static fromJson(data: Plan): OptionPlan {
    return new OptionPlan(
      data.id,
      data.key,
      data.display_name,
      data.initial_cost,
      data.price,
      data.number_of_published_projects,
    );
  }

  constructor(
    public id?: number,
    public key?: string,
    public display_name?: string,
    public initial_cost?: number,
    public price?: number,
    public number_of_published_projects?: number,
  ) {}
}
