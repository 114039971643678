import { useState, useEffect, useContext, useRef } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicProjectQuery } from 'interfaceAdapter/queries/PublicProject';
import { IProject } from 'domain/entities/Project/Project';

export const useSessions = () => {
  const diContainer = useContext(diContainerContext);
  const publicProjectQueryRef = useRef(diContainer.resolve(PublicProjectQuery));

  const [projectList, setProjectList] = useState<IProject[] | null>([]);

  const fetchProject = async () => {
    const fetchedProjects = await publicProjectQueryRef.current.fetchPushedProjects({
      limit: 9, // 3件ずつ表示するため、9件取得
      offset: 0,
    });
    setProjectList(fetchedProjects.projects);
  };

  useEffect(() => {
    fetchProject();
  }, []);

  return {
    projectList,
  };
};
