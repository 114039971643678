import fixture from './PushedProjects.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IPushedProjectsClient,
} from 'interfaceAdapter/gatewaySchemas/IPushedProjectsClient';

const PATH = `${API_LOCATION_SPREADY}/public/projects`;

// @description トップページに出す用途でDjangoAdminで設定したセッションを返す
export class PushedProjectsClient implements IPushedProjectsClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { offset = 0, limit = 20, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ offset, limit });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('セッションの取得に失敗しました');
    }
  }
}
